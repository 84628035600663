@use '../../styles/yr' as *;

.home-page__background--with-locations {
  @include mq-lte($mq-675) {
    display: none;
  }
}

.home-page__container {
  margin-top: size(2);
  margin-bottom: size(5);

  @include mq-gt($mq-675) {
    margin-bottom: size(8);
  }
}

.home-page__locations {
  .no-js & {
    display: none;
  }
}

.home-page__empty-list {
  background-color: var(--color-background-muted-core-blue);
  border-radius: size(1);
  border: 1px solid var(--color-stroke-subtle-core-blue);
  padding: size(3) size(2);
  color: var(--color-text-accent-core-blue);
}

.home-page__static {
  .js & {
    display: none;
  }
}

.home-page__static-heading {
  margin-bottom: size(3);
  max-width: rem(600px);
}

.home-page__static-search {
  max-width: rem(560px);
}
