@use '../../styles/yr' as *;

.weather-location-list-edit-panel {
  padding: size(0.5);
  display: flex;
  flex-direction: column;
  gap: size(0.5);
}

.weather-location-list-edit-panel__menu-button {
  width: 100%;
}
