@use '../../styles/yr' as *;
$two-rows-gt: $mq-450;
$four-rows-with-small-gap-gt: 820px;
$feature-content-item-border: 1px;

.featured-content {
  display: grid;
  grid-gap: size(1);

  @supports (display: grid) {
    @include mq-gt($two-rows-gt) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    @include mq-gt($four-rows-with-small-gap-gt) {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: none;
    }
  }
}
